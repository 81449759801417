import type { ReactNode } from 'react'
import { shouldShowFeature } from '~/config/features'
import { cn } from '~/utils/utils'
import { Header } from './Header'

interface ContainerProps {
  children: ReactNode
  className?: string
  containerClassName?: string
}

export function Container({
  children,
  className = '',
  containerClassName = '',
}: ContainerProps) {
  return (
    <div
      className={cn(
        'flex flex-col flex-1 sm:bg-midnight-100',
        containerClassName,
      )}
    >
      {shouldShowFeature('nav') ? <Header /> : null}
      <div className="flex-1 sm:pb-12">
        <div
          className={cn(
            'max-w-screen-sm lg:max-w-[792px] px-6 sm:px-14 pt-4 pb-12 sm:py-14 sm:mt-10 mx-auto bg-white sm:rounded-3xl',
            className,
          )}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
